import { Outlet } from 'react-router-dom';

import { Header } from './components';
import './App.css';

const App = () => (
    <div className='app'>
        <header className='app-header'>
            <Header />
        </header>
        <div className='app-body'>
            <Outlet />
        </div>
    </div>
)
export default App;
