import './GiftCard.css';
import PropTypes from "prop-types";

const GiftCard = ({text, price, symbol}) => (
    <div className='gift-card'>
        <div className='gift-card-name'>{text}</div>
        <div className='gift-card-price'>{price} {symbol}</div>
    </div>
)

GiftCard.prototype = {
    text: PropTypes.string,
    symbol: PropTypes.string,
    price: PropTypes.number,
}
export default GiftCard;
