import './CollectionCard.css';
import { LinearProgress } from '@mui/material'
import { Fragment } from 'react';
import { ImageContainer } from '../index';

const calculateProgress = (amountNeeded, amountCovered) => {
    return Math.round((amountCovered / amountNeeded) * 100);
}

const CollectionCard = ({collectionName, amountNeeded, amountCovered, qrCodeUrl, type}) => (
    <div className='collection-card'>
        <div className='collection-name'>
            <h3>{collectionName}</h3>
            Lorem Ipsum Dolores Umbridge. Lorem Ipsum Dolores Umbridge. Lorem Ipsum Dolores Umbridge.
        </div>
        <div className='collection-qr-code'>
            <ImageContainer imageUrl={qrCodeUrl} />
        </div>

        {type === 'percentage' ?
            (
                <Fragment>
                    <LinearProgress
                        className='collection-progress'
                        variant='determinate'
                        value={calculateProgress(amountNeeded, amountCovered)}>
                    </LinearProgress>
                    <div className='collection-percentage'>{calculateProgress(amountNeeded, amountCovered)}%</div>
                </Fragment>
                ) : null
        }
        {Array(amountCovered).fill(<Fragment>&#128673;</Fragment>)}

        {/*{type === 'amount' ? `${amountCovered} / ${amountNeeded}` : null}*/}

    </div>
)

CollectionCard.prototype = {}
export default CollectionCard;
