import {Container, Row, Col} from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Home.css';
import {
    Timeline,
    TimelineConnector, TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import dates from "../../data/dates.json";
import {CountDown, ImageContainer} from "../../components";
import {isToday} from "date-fns";

const Home = () => {
    const today = new Date();
    const isEventToday = (month, day) => isToday(new Date(today.getFullYear(), month - 1, day))

    return (
        <Container>
            <Row>
                <h3>Cestovatelský deník NaSváču!</h3>
            </Row>
            <Row>
                <Col lg={6} md={12} sm={12}>
                    Rádi cestujeme a věříme, že každý kout světa skrývá jedinečný příběh a zážitek. Naše dobrodružství
                    jsou stvořena nejen pro nás, ale i pro všechny, kdo touží objevovat krásy naší planety. Máme velké
                    plány a vášnivě hledáme nová místa, která bychom mohli zařadit do naší cestovatelské mapy.

                    <br/><br/>

                    Vaše tipy jsou pro nás vzácným pokladem. Rádi bychom slyšeli o vašich oblíbených místech, skrytých
                    perlách a nezapomenutelných zážitcích. Uvítáme jakékoli nápady, kam se vydat a co prozkoumat. Dejte
                    nám vědět, kam nás má naše cestovatelská vášeň zavést!


                </Col>
                <Col lg={6} md={12} sm={12}>
                    <ImageContainer imageUrl='/images/selfie-from-norway.JPG' altText='selfie' />
                </Col>
            </Row>
            <Row>
                <h3>Tik Tak</h3>
            </Row>
            <Row>
                <Col lg={6} md={12} sm={12}>
                    Rádi sledujeme časovou osu našich životních okamžiků. Je to nejen příležitost k oslavám, ale i k
                    reflexi nad tím, jak rychle čas ubíhá. S každými narozeninami přichází nejen radost ze vzpomínek,
                    ale také uvědomění si, že stárnutí je přirozenou
                    součástí našeho života.
                    <br/><br/>
                    Nicméně, místo abychom se báli stárnout, vidíme v tomto procesu
                    příležitost pro další dobrodružství. Než dosáhneme úctihodného úcvěku, toužíme
                    prozkoumat ještě spoustu míst a zažít nové vzrušující zážitky. Naše cestovatelské plány nám
                    připomínají, že život je o neustálém objevování a naplňování, a my jsme odhodláni každý okamžik
                    využít naplno.

                    <br/><br/>

                    Každý někdy zapomene a i my kolikrát přemýšlíme, kolik nám vlastně je. Tuto velice delikátní
                    informaci
                    sdílet radši nebudeme, a pojďme předstírat, že je nám 22.

                    <br/><br/>
                </Col>
                <Col lg={6} md={12} sm={12}>
                    <Timeline>
                        {dates.map(({id, day, month, hour, minute, eventName}) => (
                            <TimelineItem key={`timeline-${id}`}>
                                <TimelineOppositeContent title={`Datum: ${day}. ${month}.`}>
                                    {eventName}
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot variant='outlined' color='primary'/>
                                    <TimelineConnector/>
                                </TimelineSeparator>
                                <TimelineContent color='text.secondary'>
                                    {isEventToday(month, day)
                                        ? 'Dnes!!'
                                        : <CountDown
                                            key={`dates-${id}`}
                                            day={day}
                                            month={month}
                                            hour={hour}
                                            minute={minute}
                                        />
                                    }
                                </TimelineContent>
                            </TimelineItem>
                        ))}
                    </Timeline>
                </Col>
            </Row>
        </Container>
    );
}

Home.prototypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        account: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    })
}

export default Home;
