import {addYears, differenceInSeconds, isPast} from 'date-fns'
import {useCallback, useEffect, useState} from 'react';
import './Countdown.css';

const Countdown = ({day, month, hour, minute}) => {
    const calculateTimeRemaining = useCallback(() => {
        const today = new Date();
        const currentYear = new Date().getFullYear();
        const eventInCurrentYear = new Date(currentYear, month - 1, day, hour, minute);
        const eventInNextYear = addYears(eventInCurrentYear, 1);
        const isEventDateInThePast = isPast(eventInCurrentYear);

        const dateToCompare = isEventDateInThePast ? eventInNextYear : eventInCurrentYear

        return differenceInSeconds(dateToCompare, today);
    }, [day, month, hour, minute]);

    const [timeRemaining, setTimeRemaining] = useState(null);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        setTimeRemaining(calculateTimeRemaining());

        return () => {
            clearInterval(countdownInterval);
        };
    }, [calculateTimeRemaining]);

    const days = Math.floor(timeRemaining / (60 * 60 * 24));
    // const hours = Math.floor((timeRemaining % (60 * 60 * 24)) / (60 * 60));
    // const minutes = Math.floor((timeRemaining % (60 * 60)) / (60));
    // const seconds = Math.floor((timeRemaining % (60)));

    const dayText = () => {
        if (days === 1) {
            return 'den';
        } else if (days === 2 || days === 3 || days === 4) {
            return 'dny';
        } else {
            return 'dní';
        }

    }
    return (
        <div>
            {days ? `${days} ${dayText()}` : null}
        </div>
    );
};

export default Countdown;
