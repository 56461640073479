import { Container, Row, Col } from 'react-bootstrap';
import { PlaceContainer } from '../../components';

import placesDummyData from '../.././data/places.json';
import futurePlacesDummyData from '../.././data/future-places.json';

import './OurPlans.css';

// const getMap = (id) => "https://wanderlog.com/api/shareMap/" + id;

const OurPlans = () => (
    <Container>
        <Row>
            <Col>
                <h3>Kam chceme jet?</h3>
                <br />

                 <Row>
                    {futurePlacesDummyData.places.map(({id, link, image, name}) => (
                        <PlaceContainer key={`aheadPlace-${id}`} name={name} link={link} image={image} />
                    ))}
                </Row>
                <br /><br />

                {/*Kam chceme jet, jaké jsou naše plány do budoucna.*/}
                {/*<br /><br />*/}

                {/*Ke každému výletu by mohl být link do WanderLogu.*/}
                {/*<br /><br />*/}

            </Col>
        </Row>
        {/*<Row>*/}
        {/*    {futurePlacesDummyData.places.map(({id, link, image, name}) => (*/}
        {/*        <PlaceContainer key={`futurePlace-${id}`} name={name} link={link} image={image} />*/}
        {/*    ))}*/}
        {/*</Row>*/}
        <hr />
        <br />
        <Row>
            <Col>
                <h3>Naše Cestovatelská Stopa</h3>
                <br />

                Vstupte na stránku, kde se zvěčňují stopy našich cestovatelských dobrodružství, ať už jsou slunečné či
                se zataženým nebem. Tady najdete seznam míst, která jsme s radostí navštívili, i když cestování nemusí
                být vždy jednoduché. Každé místo má svůj jedinečný příběh, který nás naučil, jak zvládat i výzvy, které
                cestování občas přináší.
                <br /><br />

                Chceme vás také pozvat k prozkoumání našeho Wanderlogu, interaktivní aplikace, která nám umožňuje sdílet
                podrobnější pohledy na naše dobrodružství. Wanderlog nám slouží jako cestovatelský deník, kde můžeme
                sdílet místa a naše zážitky a tipy z cest. Na našem Wanderlogu můžete prozkoumat naše zápisy, kde se
                ponoříte do našich dobrodružství a možná i získáte inspiraci pro vaše vlastní
                cestovatelské plány. Připojte se k nám a procházejte s námi tímto virtuálním deníkem, který nám
                připomíná, že nejen krásná panoramata, ale i náročné momenty tvoří tu nejautentičtější cestovatelskou
                paletu.
                <br /><br />
            </Col>
        </Row>

        <Row>
            {placesDummyData.places.map(({id, link, image, name}) => (
                <PlaceContainer key={`visitedPlace-${id}`} name={name} link={link} image={image} />
            ))}
        </Row>
    </Container>
)
export default OurPlans;
