import './Gifts.css';

import {Container, Row, Col} from 'react-bootstrap';
import {GiftCard, ImageContainer} from "../../components";
import {getYear} from "date-fns";
import {useState} from "react";

const CurrencyCodes = {
    CZK: 'CZK',
    INR: 'INR',
}

const CurrencySymbols = {
    [CurrencyCodes.CZK]: 'Kč',
    [CurrencyCodes.INR]: '₹',
}

const IndianGiftItems = [
    {
        giftName: 'Večeře v restauraci pro dva',
        prices: {
            [CurrencyCodes.CZK]: '270',
            [CurrencyCodes.INR]: '994.8',
        }
    }, {
        giftName: 'Lokální pivo:',
        prices: {
            [CurrencyCodes.CZK]: '43',
            [CurrencyCodes.INR]: '158.4',
        }
    }, {
        giftName: '1 km taxi',
        prices: {
            [CurrencyCodes.CZK]: '5.4',
            [CurrencyCodes.INR]: '19.8',
        }
    }, {
        giftName: 'AirBnb na noc',
        prices: {
            [CurrencyCodes.CZK]: '750 - 1500',
            [CurrencyCodes.INR]: '2763 - 5526',
        }
    }
]

const Gifts = () => {
    const [selectedCurrency, setCurrency] = useState(CurrencyCodes.CZK);
    const yearsTogether = getYear(new Date()) - 2017;

    const url = `https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=2302345070&bankCode=2010&currency=CZK&vs=3&compress=false`;

    return (
        <Container>
            <Row>
                <h3>Sdílejte naše cesty</h3>
            </Row>
            <Row>
                <Col sm={12} md={8}>
                    Na této stránce máte možnost nám projevit svou štědrost
                    jinak než tradičními dárky. Rádi bychom vás poprosili o vaši podporu na našich cestovatelských
                    dobrodružstvích. Vaše příspěvky nám pomohou objevit nová místa, poznat různé kultury a zažít
                    nezapomenutelná dobrodružství. Skenujte náš QR kód a přidejte se k našemu cestovatelskému snu!
                    <br/><br/>
                    Žijeme v malém prostoru bytu a náš prostor je omezený. Už tak máme hodně věcí a nedělá nám dobře
                    věci
                    vyhazovat. Obecně nemáme rádi plastové obaly a neradi plýtváme.
                    <br/><br/>
                    Oceníme dary, které jsou praktické. Zároveň spolu žijeme už {yearsTogether} let a většinu věcí,
                    které by se nám
                    hodili
                    už máme.
                    <br/><br/>
                    Chápeme, že nám chcete udělat radost. Ale více věcí v našem bytě nám přinese jen špatnou náladu.
                    Pošlete
                    nám raději peníze a my si za ně pořídíme zážitky a vzpomínky :)

                </Col>
                <Col sm={12} md={4}>
                    <ImageContainer imageUrl={url} altText='qr code'/>
                </Col>
            </Row>
            <hr/>
            <br/>
            <Row>
                <h3>Indie</h3>
            </Row>
            <Row>
                <Col sm={12} lg={7}>
                    Přípravy na naši plánovanou cestu do Indie nám otevírají bránu do fascinujícího světa bohaté
                    historie, různorodých kultur a ohromujících krajin. Indie, známá svou pestrostí od Himalájí po
                    tropická pobřeží, láká návštěvníky svými barvitými trhy, vůněmi exotických koření a nespočtem
                    historických památek.

                    <br/><br/>
                    Namísto drahých hotelů se těšíme na objevování tradičních ubytování, kde budeme mít možnost nasát
                    atmosféru místního života. Indie, s její bohatou historií a pestrou kuchyní, nás láká k prozkoumání
                    trhů a malých uliček.

                    <br/><br/>
                    Plánujeme si v Indii užít měsíc, abychom mohli opravdu proniknout do různorodých aspektů této
                    fascinující země. Naše cesta bude zvláště výjimečná, protože jsme si vybrali období Diwali,
                    festivalu světel, který oslavuje vítězství světla nad temnotou. Chceme být svědky tohoto úžasného
                    svátku, který je pro Indii tak významný.

                    <br/><br/>
                    Jsme nadšeni, že naše cestování bude spojené s pravou indickou kulturou a autentickými zážitky.
                    Těšíme se na každý okamžik tohoto dobrodružství, kde se budeme snažit vidět Indie tak, jak ji vidí a
                    prožívají sami její obyvatelé.
                </Col>
                <Col sm={12} lg={5}>
                    <ImageContainer imageUrl='/images/diwali.jpg' altText='diwali'/>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={7} xxl={6}>
                    <ImageContainer
                        redirectLink='https://wanderlog.com/view/gjbfybygmz/trip-to-india/shared'
                        imageUrl='https://wanderlog.com/api/shareMap/gjbfybygmz'
                        altText='wander log'
                    />
                </Col>
                <Col sm={12} lg={5} xxl={6}>
                    <div className='gift-card-container'>
                        <div className='gift-card-button-holder'>
                            {Object.keys(CurrencyCodes).map((code, index) => (
                                <button
                                    key={index}
                                    className={code === selectedCurrency ? 'button-active-currency' : undefined}
                                    onClick={() => setCurrency(code)}>
                                    {code}
                                </button>
                            ))}
                        </div>
                        {IndianGiftItems.map(({giftName, prices}, index) => (
                            <GiftCard
                                key={index}
                                text={giftName}
                                price={prices[selectedCurrency]}
                                symbol={CurrencySymbols[selectedCurrency]}
                            />
                        ))}
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
export default Gifts;
