import { Fragment, useState } from 'react';

export const withErrors = (Component) => (props) => {
    const [error, setErrorState] = useState(null);
    const setErrors = (e) => {
        setErrorState(e.toString());
    };
    return (
        <Fragment>
            <Component {...props} setErrors={setErrors} />

            <div>{error}</div>
        </Fragment>
    );
}