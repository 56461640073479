import './MessageCard.css';
import { format, fromUnixTime } from 'date-fns';
import { Col, Row } from 'react-bootstrap';

const MessageCard = ({from, message, time}) => {
    const date = format(fromUnixTime(time), 'dd. MM. yyyy');

    return (
        <Row className='message-card'>
            <Col sm={12} lg={2} >
                <strong>{from}</strong><br />
                {date}
            </Col>
            <Col sm={12} lg={10} className='message'>
                {message}
            </Col>
        </Row>
    );
}
export default MessageCard;
