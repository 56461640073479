import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MessageCard } from '../../components';
import { get, withErrors } from '../../utils';

import './SendUsMoney.css';

const MESSAGE_TEXT_AREA_ID = 'message-text-area';

const SendUsMoney = ({setErrors}) => {
    const [data, setData] = useState([]);
    const [customUrl, setCustomUrl] = useState(null);
    const [customMessage, setCustomMessage] = useState(null);

    const onSubmit = () => {
        setCustomUrl(`https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=2302345070&bankCode=2010&currency=CZK&vs=3&compress=false&message=${customMessage}`);
    }

    const onChange = (event) => {
        setCustomMessage(event.target.value);
    }

    useEffect(() => {
        get('/Transactions')
            .then(
                (response) => setData(response),
                (error) => setErrors(error),
            );
    }, [setErrors])

    return (
        <Container>
            <Row>
                <Col lg={8} md={6} sm={12}>

                    <h3> Vítejte na našem dárkovém portálu! </h3>
                    <br />

                    Na této stránce máte možnost nám projevit svou štědrost
                    jinak než tradičními dárky. Rádi bychom vás poprosili o vaši podporu na našich cestovatelských
                    dobrodružstvích. Vaše příspěvky nám pomohou objevit nová místa, poznat různé kultury a zažít
                    nezapomenutelná dobrodružství. Skenujte náš QR kód a přidejte se k našemu cestovatelskému snu!

                    <br /><br />

                    <h3> Nezapomeňte nám zanechat vzkaz! </h3>
                    <br />

                    Připravili jsme pro vás speciální vzkazový formulář vpravo.
                    Vzkaz, který do formuláře vyplníte, se propíše do QR kódu a přijde společne s platbou. Napište nám
                    pár slov, sdílejte své přání či
                    tipy na místa, která bychom měli navštívit. Chceme vám poděkovat za každou částku, kterou nám
                    věnujete, i za slova, která nám zanecháte. Ať už to jsou přání k narozeninám, oblíbené citáty nebo
                    tipy na skvělá místa k navštívení. Vaše zpráva se zobrazí níže na stránce, kde bude viditelná pro
                    všechny, takže žádný sposťárny!! Částka, kterou jste přispěli, nebude vidět, ukáže se jen jméno, datum a psaníčko :)

                    <br /><br />
                </Col>
                <Col lg={4} md={6} sm={12}>
                    <div className='send-us-money-form'>
                        Napište nám vzkaz!
                        <br />
                        <span className="note">Poznámka: Vzkaz se přidá k Vaší platbě jako zpráva pro příjemce.</span>
                        <textarea
                            className='form-input'
                            id={MESSAGE_TEXT_AREA_ID}
                            onChange={onChange}
                        />

                        <button className='regenerate-qr-code-button' onClick={onSubmit}>
                            {customUrl ? 'Přegenerovat QR kód' : 'Vygenerovat QR kód'}
                        </button>

                        {customUrl && <img
                            className='qr-code-image'
                            alt='qr code'
                            src={customUrl}
                        />}

                        Nefunguje QR kód? Použijte číslo účtu: <code>2302345070/2010</code>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <h2>Vzkazy</h2>
                    <br />
                    <Container>
                        {data.sort((a, b) => a.date > b.date ? -1 : 1).map(item => (
                            <MessageCard
                                key={`message-${item.id}`}
                                from={item.account}
                                time={item.date}
                                message={item.message || '-'}
                            />
                        ))}
                    </Container>

                </Col>
            </Row>
            <br /><br />
        </Container>
    );
}

SendUsMoney.prototypes = {
    data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
        account: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
    })
}

export default withErrors(SendUsMoney);
