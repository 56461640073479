import './PlaceContainer.css';
import { Col } from 'react-bootstrap';

const PlaceContainer = ({link, image, name}) => (
    <Col
        xs={6} sm={6} md={4} lg={3} xl={2} xxl={2}
        style={{margin: '10px 0'}}
    >
        <a
            className='visited-card-href'
            href={link}
            target='_blank'
            rel='noreferrer'
        >
            <div className='visited-card'>
                <div className='card-image'><img src={image} alt={name} /></div>
                <div className='card-text'>{name}</div>
            </div>
        </a>
    </Col>
)
export default PlaceContainer;
