import './ImageContainer.css';
import PropTypes from "prop-types";

const ImageContainer = ({imageUrl, altText, size = 100, redirectLink = null}) => (
    <div style={{width: `${size}%`}} className='image-container'>
        {redirectLink
            ? <a target='_blank' rel='noreferrer' href={redirectLink}>
                <img src={imageUrl} alt={altText}/>
            </a>
            : <img src={imageUrl} alt={altText}/>}
    </div>
)

ImageContainer.prototype = {
    imageUrl: PropTypes.string.isRequired,
    altText: PropTypes.string,
    size: PropTypes.number,
    redirectLink: PropTypes.string,
}
export default ImageContainer;
