import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { RouterPaths } from '../../constants';

import './Header.css';

const Header = () => (
    <div className='sticky'>
        <Container>
            <Row style={{marginTop: '0', marginBottom: '0'}}>
                <Col xs={5} className='header-column'>
                    <h1>
                        Na Sváču
                    </h1>
                </Col>
                <Col xs={7} style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Navbar sticky="top" collapseOnSelect expand='md' style={{justifyContent: 'flex-end'}}>
                        <Navbar.Toggle aria-controls='basic-navbar-nav' />
                        <Navbar.Collapse>
                            <Nav className='me-auto'>
                                <LinkContainer to={RouterPaths.DEFAULT}>
                                    <Nav.Link className='link'>Úvod</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={RouterPaths.GIFTS}>
                                    <Nav.Link className='link'>Chcete přispět?</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to={RouterPaths.PLANS}>
                                    <Nav.Link className='link'>Naše plány</Nav.Link>
                                </LinkContainer>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Col>
            </Row>
        </Container>
    </div>
)
export default Header;
