import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { RouterPaths } from './constants';
import App from './App';
import {
    Home,
    OurPlans,
    Gifts,
} from './pages';

export const router = createBrowserRouter([{
    path: RouterPaths.DEFAULT,
    element: <App />,
    children: [
        {
            path: RouterPaths.DEFAULT,
            element: <Home />,
        }, {
            path: RouterPaths.PLANS,
            element: <OurPlans />,
        }, {
            path: RouterPaths.GIFTS,
            element: <Gifts />,
        },
        // {
        //     path: RouterPaths.TEST,
        //     element: <Test />
        // }
    ]
}]);
